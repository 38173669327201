import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCount, setCount } from '../../redux/slices/tabSlice'
import cn from './People.module.sass'
import Slides from './Slides'


const tabs = [
  'Подбор идеального объекта',
  'Независимая оценка рынка',
  'Юридическая поддержка',
  'Полная конфиденциальность',
  'Минимум вашего времени',
]


const People = () => {

  const { counter, bgImage } = useSelector(selectCount)
  const dispatch = useDispatch()

  const setActiveTabStyles = (i) => {
    const isActive = counter === i
    return {
      opacity: isActive ? '1' : '0.5',
      width: isActive ? '293px' : '100%',
      background: isActive ? 'linear-gradient(100.3deg, rgba(255, 255, 255, 0) 7.91%, rgba(255, 255, 255, 0.3) 46.12%, rgba(255, 255, 255, 0) 87.65%), linear-gradient(96.58deg, #FFD1A0 -4.63%, #FBBC78 98.2%)' : '',
      clipPath: isActive ? 'polygon(0% 0%, 93.5% 0%, 100% 50%, 93.5% 100%, 0% 100%)' : ''
    }
  }

  return (
    <div className={cn.root} style={{ backgroundImage: `url(${bgImage[counter]})` }}>

      <div className={cn.inner}>
        <h3 className={cn.title}>
          People — официальный представитель крупнейших застройщиков Северного Кипра
        </h3>
        <div className={cn.box}>
          <ul className={cn.tabs}>
            {tabs.map((item, i) => {
              return (
                <li
                  key={i}
                  className={cn.tabs_item}
                  style={setActiveTabStyles(i)}
                  onClick={() => dispatch(setCount(i))}
                >
                  {item}
                </li>
              )
            })}
          </ul>
          <Slides />
        </div>
      </div>

    </div>
  )
}

export default People
