import React from 'react'
import cn from './MassMedia.module.sass'

const SliderItem = ({ imageUrl, text }) => {
  return (
    <div className={cn.item}>
      <img src={imageUrl} alt="world mass media" />
      <p>{text}</p>
    </div>
  )
}

export default SliderItem
