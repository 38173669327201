import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

import { getUserCountry } from './redux/slices/formSlice';

import Home from './pages/Home';
import Thank from './pages/Thank';

const FB_ID = '1463010787436697';

function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    const advancedMatching = { em: 'showy.person@gmail.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init(FB_ID, advancedMatching, options);
    ReactPixel.pageView();

    dispatch(getUserCountry())

  }, []);

  return (
    <div className="wrapper">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/thank' element={<Thank />} />
      </Routes>
    </div>
  );
}

export default App;
