import React from 'react'
import cn from '../People.module.sass'

const Support = () => {
  return (
    <div className={`${cn.support} ${cn.slide}`}>
      <p>
        До&nbsp;совершения сделки юристы проконсультируют вас по&nbsp;поводу налогообложения и&nbsp;законодательства. На&nbsp;простом языке ответят на&nbsp;все вопросы.
      </p>
      <p>
        Во&nbsp;время приобретения недвижимости наши юристы проверят все документы и&nbsp;будут защищать ваши интересы. Вам обеспечат полное сопровождение и&nbsp;прозрачность сделки.
      </p>
    </div>
  )
}

export default Support
