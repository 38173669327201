import React from 'react'
import cn from './Form.module.sass'
import checkedIcon from '../../images/checked.svg';
import { useState } from 'react';


const InputCheckbox = ({ id, register, errors }) => {
  const [inputChecked, setInputChecked] = useState(true)

  return (
    <label htmlFor={id} className={cn.label}>
      <input
        {...register('checkbox', {
          required: true,
        })}
        id={id}
        type="checkbox"
        checked={inputChecked}
        onChange={() => setInputChecked(!inputChecked)}
        className={cn.input_checkbox}
      />
      <span style={{ backgroundImage: inputChecked ? `url(${checkedIcon})` : '', borderColor: inputChecked ? '#57A5D6' : '#b41c1c' }} className={cn.fake}></span>
      <p style={{ color: inputChecked ? '#000000' : '#b41c1c' }} className={cn.label_text}>
        Нажимая на кнопку, вы соглашаетесь с <a href="https://docs.google.com/document/d/1e4kwqul6uOZXc-pyD9DXXQEU7jYlWNTzSIJlOUu2i4Y/edit?usp=sharing" target='_blank' rel="noreferrer" style={{ color: inputChecked ? '#0063A0' : '#b41c1c', borderBottomColor: inputChecked ? '#0063A0' : '#b41c1c' }}>условиями обработки персональных данных.</a>
      </p>
    </label>
  )
}

export default InputCheckbox
