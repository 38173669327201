import React, { useState, useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel';
import { disableBodyScroll } from 'body-scroll-lock';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { setFormContent, setVisibleModal } from '../../redux/slices/modalSlice'

import cn from './Menu.module.sass'

import phoneIcon from '../../images/menu_phone.svg';
import wupImage from '../../images/whatsup.svg'
import wupImageSm from '../../images/whatsup-sm.svg'

const Menu = () => {
  const [whatsAppIcon, setWhatsAppIcon] = useState(wupImage)
  const dispatch = useDispatch()


  useEffect(() => {
    if (document.documentElement.offsetWidth < 900) {
      setWhatsAppIcon(wupImageSm)
    }
  }, [])

  const handleButton = (e) => {
    e.preventDefault()
    dispatch(setFormContent({ btnText: 'Заказать звонок', title: 'Закажите звонок' }))
    dispatch(setVisibleModal(true))
    disableBodyScroll(document)
    ReactPixel.track('AddToCart', { toCart: 'AddToCartEvent' });
  }

  const handleWAButton = () => ReactPixel.track('Purchase', { Purchase: 'PurchaseEvent' });
  
  return (
    <div className={cn.inner}>
      <div className={cn.box_inner}>
        <div className={cn.box}>
          <Link to='/' className={cn.logo}>
            <span>PEOPLE</span>
            <span>Real Estate</span>
          </Link>
          <p className={cn.text}>
            С&nbsp;2015 года помогли <br /> 1700+ клиентам
          </p>
        </div>
        <a href="tel:905488557173" className={cn.phone}>
          <img src={phoneIcon} alt="" />
        </a>
      </div>
      <div className={cn.box}>
        <div className={cn.contact}>
          <a href="tel:905488557173">+90 548 855 71 73</a>
          <span>сейчас работаем</span>
        </div>
        <a href="https://wa.me/+79629652000" onClick={handleWAButton} target='_blank' rel="noreferrer" className={cn.link}>
          Написать в whatsapp
          <img src={whatsAppIcon} alt="" />
        </a>
        <a onClick={handleButton} href="#" className={cn.link}>
          Заказать звонок
        </a>
      </div>
    </div >
  )
}

export default Menu
