import React from 'react'
import ReactPixel from 'react-facebook-pixel';
import { useDispatch } from 'react-redux'
import cn from './Objects.module.sass'
import { disableBodyScroll } from 'body-scroll-lock';
import { setObjectModal } from '../../redux/slices/modalSlice';

const Object = ({ imgUrl, name, topSale, hitSale }) => {
  const dispatch = useDispatch()

  const handleButtonClick = () => {
    dispatch(setObjectModal(true))
    disableBodyScroll(document)
    
    ReactPixel.track('AddToCart', { toCart: 'AddToCartEvent' });
  }

  return (
    <div className={cn.item}>
      {topSale && <div className={cn.item_sale} style={{ backgroundColor: '#FF696D' }}>{topSale}</div>}
      {hitSale && <div className={cn.item_sale} style={{ backgroundColor: '#72CDFF' }}>{hitSale}</div>}
      <p className={cn.item_title}>{name}</p>
      <img className={cn.item_img} src={imgUrl} alt={name} />
      <button onClick={handleButtonClick} className={cn.item_btn}>скачать презентацию</button>
    </div>
  )
}

export default Object
