import React, { useEffect } from 'react'

const MarquizButton = () => {

  useEffect(() => {
    (function (t, p) { window.Marquiz ? window.Marquiz.add([t, p]) : document.addEventListener('marquizLoaded', function () { window.Marquiz.add([t, p]) }) })('Button', { id: '63da10b81d6368004f7d01d5', buttonText: 'Пройти тест', bgColor: '#d34085', textColor: '#fff', rounded: true, shadow: 'rgba(211, 64, 133, 0.5)', blicked: true })
  }, [])

  return (
    <div data-marquiz-id="63da10b81d6368004f7d01d5"></div>
  )
}

export default MarquizButton
