import React from 'react'
import cn from './Quiz.module.sass'
import imagePNG1 from '../../images/quiz/1.png'
import imageWebp1 from '../../images/quiz/1.webp'
import imagePNG2 from '../../images/quiz/2.png'
import imageWebp2 from '../../images/quiz/2.webp'
import imagePNG3 from '../../images/quiz/3.png'
import imageWebp3 from '../../images/quiz/3.webp'
import MarquizButton from './MarquizButton'
import ImgWithFallback from '../ImgWithFallback'


const items = [
  {
    image: <ImgWithFallback fallback={imagePNG1} src={imageWebp1} alt='magazine' />, text: 'Подборку из 10 жилых комплексов, подобранных индивидуально под ваши параметры'
  },
  {
    image: <ImgWithFallback fallback={imagePNG2} src={imageWebp2} alt='document' />, text: 'Сравнительный анализ каждого ЖК'
  },
  {
    image: <ImgWithFallback fallback={imagePNG3} src={imageWebp3} alt='bus' />, text: 'Бесплатный ознакомительный тур с проживанием'
  }
]


const Quiz = () => {


  return (
    <section>
      <div className="container">
        <div className={cn.content}>
          <h3 className={cn.title}>
            Пройдите тест за&nbsp;1&nbsp;минуту и&nbsp;сэкономьте&nbsp;80% времени на&nbsp;поиске недвижимости на&nbsp;Северном Кипре
          </h3>
          <p className={cn.subtitle}>
            После прохождения теста вы получите
          </p>
          <ul className={cn.list}>
            {items.map((item, i) => {
              return (<li className={cn.list_item} key={i}>
                {item.image}
                <p>{item.text}</p>
              </li>)
            })}
          </ul>
        </div>
        <MarquizButton />
      </div>
    </section>
  )
}

export default Quiz
