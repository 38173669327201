import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isVisibleObjectModal: false,
  isVisible: false,
  formContent: {
    title: "",
    btnText: "",
    id: "checkbox_modal",
  },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setObjectModal(state, action) {
      state.isVisibleObjectModal = action.payload;
    },
    setFormContent(state, action) {
      state.formContent = {
        ...state.formContent,
        ...action.payload,
      };
    },
    setVisibleModal(state, action) {
      state.isVisible = action.payload;
    },
  },
});

export const { setFormContent, setVisibleModal, setObjectModal } =
  modalSlice.actions;

export const selectModal = (state) => state.modal;

export default modalSlice.reducer;
