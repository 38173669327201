import React from 'react'
import prev_arrow from '../../images/massmedia/arrow_prev.svg'
const PrevArrow = ({ onClick, className }) => {

  return (
    <img onClick={onClick} className={className} src={prev_arrow} alt="" />
  )
}

export default PrevArrow
