import React from 'react'
import cn from './Partners.module.sass';
import logo1 from '../../images/partners/1.png'
import logo2 from '../../images/partners/2.png'
import logo3 from '../../images/partners/3.png'
import logo4 from '../../images/partners/4.png'
import logo5 from '../../images/partners/5.png'
import logo6 from '../../images/partners/6.png'
import logo7 from '../../images/partners/7.png'
import logo8 from '../../images/partners/8.png'
import logo9 from '../../images/partners/9.png'
import logo10 from '../../images/partners/10.png'

const items = [
  { image: logo1 },
  { image: logo2 },
  { image: logo3 },
  { image: logo4 },
  { image: logo5 },
  { image: logo6 },
  { image: logo7 },
  { image: logo8 },
  { image: logo9 },
  { image: logo10 },
]

const Partners = () => {
  return (
    <section className={cn.root}>
      <div className="container">
        <div className={cn.inner}>
          <h3 className={cn.title}>
            Официальный представитель ведущих застройщиков Северного Кипра
          </h3>
          <div className={cn.items}>
            {items.map((item, i) => {
              return (
                <div key={i} className={cn.item}>
                  <img src={item.image} alt="" />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Partners
