import { createSlice } from "@reduxjs/toolkit";

import selectionImage from "../../images/magazine.png";
import markImage from "../../images/contract.png";
import supportImage from "../../images/gavel.png";
import confImage from "../../images/shield.png";
import timeImage from "../../images/clock.png";

const initialState = {
  counter: 0,
  bgImage: [selectionImage, markImage, supportImage, confImage, timeImage],
};

export const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    setCount(state, action) {
      state.counter = action.payload;
    },
    incrementCount(state, action) {
      state.counter = ++state.counter;
    },
    decrementCount(state, action) {
      state.counter = --state.counter;
    },
  },
});

export const { setCount, incrementCount, decrementCount } = tabSlice.actions;

export const selectCount = (state) => state.tab;

export default tabSlice.reducer;
