import React from 'react'
import nextArrowIcon from '../../images/nextArrow.svg'

const NextArrowSlider = ({ onClick, className }) => {
  return (
    <img src={nextArrowIcon} onClick={onClick} className={className} alt="" />
  )
}

export default NextArrowSlider
