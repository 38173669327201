import React from 'react'
import cn from './Contact.module.sass'
import bgImg from '../../images/contact.png'
import bgImgWebp from '../../images/contact.webp'
import bgImgSm from '../../images/contact-sm.png'
import bgImgSmWebp from '../../images/contact-sm.webp'
import Form from '../Form'
import waIcon from '../../images/contacts/wa.svg';
import telegIcon from '../../images/contacts/teleg.svg';
import instIcon from '../../images/contacts/inst.svg';
import phoneIcon from '../../images/contacts/phone.svg';
import { useState, useEffect } from 'react';
import { isWebpSupported } from 'react-image-webp/dist/utils';

const formContent = {
  id: 'checkbox_4',
  title: 'Закажите звонок',
  btnText: 'Заказать звонок'
}

const Contact = () => {

  const [bgImage, setBgImage] = useState(isWebpSupported() ? bgImgWebp : bgImg)

  useEffect(() => {
    if (document.documentElement.offsetWidth < 1199) {
      setBgImage(isWebpSupported() ? bgImgSmWebp : bgImgSm)
    }
  }, [])

  return (
    <section style={{ backgroundImage: `url(${bgImage})` }} className={cn.root}>
      <div className="container">
        <div className={cn.inner}>
          <div className={cn.content}>
            <div className={cn.title}>
              Свяжитесь с&nbsp;нами
              удобным способом
            </div>
            <ul className={cn.list}>
              <li className={cn.list_item}>
                <img src={instIcon} alt="" />
                <a href="https://instagram.com/anatoliy_nedv" target='_blank' rel="noreferrer">anatoliy_nedv</a>
              </li>
              <li className={cn.list_item}>
                <img src={phoneIcon} alt="" />
                <a href="tel:905488557173">+90 548 855 71 73</a>
              </li>
              <li className={cn.list_item}>
                <img src={telegIcon} alt="" />
                <a href="https://t.me/Finchase" target='_blank' rel="noreferrer">https://t.me/Finchase</a>
              </li>
              <li className={cn.list_item}>
                <img src={waIcon} alt="" />
                <a href="https://wa.me/+79629652000" target='_blank' rel="noreferrer">wa.me/+79629652000</a>
              </li>
            </ul>
            <div className={cn.video}>
              <h4 className={cn.video_title}>
                Посмотрите видео
              </h4>
              <a target='_blank' href="https://youtu.be/S359mCFQGgU" className={cn.video_link} rel="noreferrer">
                15 причин переехать на Северный Кипр
              </a>
            </div>
          </div>
          <Form {...formContent} />
        </div>
      </div>
    </section>
  )
}

export default Contact
