import React from 'react'
import Form from '../Form'
import cn from './Study.module.sass'
import bgImage from '../../images/book.png'
import bgImageWebp from '../../images/book.webp'
import People from '../People'
import { isWebpSupported } from 'react-image-webp/dist/utils';

const formContent = {
  title: 'Скачайте полный каталог лучших объектов Северного Кипра 2023 года',
  btnText: 'скачать каталог'
}

const Study = () => {
  return (
    <section style={{ backgroundImage: `url(${isWebpSupported() ? bgImageWebp : bgImage})` }} className={cn.root}>
      <div className="container">
        <div className={cn.inner}>
          <div className={cn.row}>
            <div className={cn.content}>
              <h3 className={`${cn.title} title`}>
                Желаете изучить
                больше объектов?
              </h3>
              <div className={cn.content_items}>
                <div className={cn.content_item}>
                  <div className={cn.content_box}>
                    <span className={cn.content_num}>/ 01</span>
                    <div className={cn.content_line}></div>
                  </div>
                  <p className={cn.content_text}>
                    <span>В&nbsp;нашей базе 1000+ объектов</span> <br /> Мы&nbsp;собрали лучшие предложения острова в&nbsp;каталог, чтобы вы&nbsp;сэкономили свое время вместо долгих поисков
                  </p>
                </div>
                <div className={cn.content_item}>
                  <div className={cn.content_box}>
                    <span className={cn.content_num}>/ 02</span>
                    <div className={cn.content_line}></div>
                  </div>
                  <p className={cn.content_text}>
                    Мы&nbsp;лично посетили и&nbsp;проверили на&nbsp;безопасность каждый объект перед добавлением его в&nbsp;каталог
                  </p>
                </div>
              </div>
            </div>
            <Form title={formContent.title} btnText={formContent.btnText} id={'checkbox_1'} />
          </div>
        </div>
      </div>
      <People />
    </section>
  )
}

export default Study
