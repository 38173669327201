import React from 'react'
import Slider from "react-slick";
import NextArrowSlider from '../Arrows/NextArrowSlider'
import PrevArrowSlider from '../Arrows/PrevArrowSlider'

const ObjectsSlider = ({ items }) => {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    // lazyLoad: 'ondemand',
    nextArrow: <NextArrowSlider />,
    prevArrow: <PrevArrowSlider />
  };

  return (

    <div>
      <Slider {...settings}>
        {items}
      </Slider>
    </div>
  )
}

export default ObjectsSlider
