import React from 'react'
import cn from './Channel.module.sass'
import playIcon from '../../images/play.svg'
import { useDispatch } from 'react-redux'
import { setVisibleFrame } from '../../redux/slices/iframeSlice'
import { disableBodyScroll } from 'body-scroll-lock';


const Video = ({ title, id, imageUrl }) => {
  const dispatch = useDispatch()

  const setPopupVideo = (id) => {
    dispatch(setVisibleFrame(id))
    disableBodyScroll(document)
  }

  return (
    <div onClick={() => setPopupVideo(id)} className={cn.video_inner}>
      <div className={cn.video}>
        <img className={cn.image} src={imageUrl} alt={title} />
        <img className={cn.play} src={playIcon} alt="play icon" />
      </div>
      <p className={cn.video_title}>{title}</p>
    </div>
  )
}

export default Video
