import React from 'react';
import Causes from '../components/Causes';
import Channel from '../components/Channel';
import Complex from '../components/Complex';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import MainScreen from '../components/MainScreen';
import MassMedia from '../components/MassMedia';
import Modals from '../components/Modals';
import Objects from '../components/Objects';
import Partners from '../components/Partners';
import Quiz from '../components/Quiz';
import Reputation from '../components/Reputation';
import Study from '../components/Study';
import Tour from '../components/Tour';


const Home = () => {
  return (
    <>
      <MainScreen />
      <Quiz />
      <Objects />
      <Causes />
      <MassMedia />
      <Study />
      <Reputation />
      <Channel />
      <Complex />
      <Partners />
      <Tour />
      <Contact />
      <Footer />
      <Modals />
    </>
  )
}

export default Home
