import React from 'react'
import Form from '../Form'
import cn from './Tour.module.sass'
import bgImg from '../../images/tour.png'

const formContent = {
  id: 'checkbox_3',
  title: 'Забронируйте место',
  btnText: 'забронировать'
}

const list = [
  { title: '3 ночи в отеле' },
  { title: 'Трансфер из аэропорта' },
  { title: 'Экскурсия по знаковым местам' },
  { title: 'Посещение лучших объектов недвижимости' },
]

const Tour = () => {
  return (
    <div style={{ backgroundImage: `url(${bgImg})` }} className={cn.root}>
      <div className="container">
        <div className={cn.inner}>
          <div className={cn.content}>
            <h2 className={cn.title}>
              Отправьтесь в&nbsp;тур
              по&nbsp;недвижимости Северного Кипра
            </h2>
            <ul className={cn.list}>
              {list.map((item, i) => {
                return (
                  <li key={i}>{item.title}</li>
                )
              })}
            </ul>
            <div className={cn.box}>
              <p className={cn.box_text}>
                При покупке недвижимости компенсируем еще и&nbsp;перелет ✈️
              </p>
            </div>
          </div>
          <Form {...formContent} />
        </div>
      </div>
    </div>
  )
}

export default Tour
