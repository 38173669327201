import React from 'react'
import Menu from '../Menu';
import cn from './Footer.module.sass';

const Footer = () => {
  return (
    <div className={cn.root}>
      <div className="container">
        <Menu />
      </div>
    </div>
  )
}

export default Footer
