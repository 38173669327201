import React from 'react'
import cn from '../People.module.sass'
import Slider from "react-slick";
import NextArrowSlider from '../../Arrows/NextArrowSlider';
import PrevArrowSlider from '../../Arrows/PrevArrowSlider';

const items = [
  { num: '/ 01', title: 'Уже 8 лет наша команда лично посещает объекты зарубежной недвижимости', text: 'Мы предложим только те комплексы, в которых хотели бы жить сами. Мы знаем о них все ОТ и ДО, каждый плюс и минус' },
  {
    num: '/ 02', title: 'В нашей базе 1000+ объектов', text: 'Задав всего 7-10 уточняющих вопросов, вам подберут объект, который идеально подойдет под ваши параметры'
  },
  {
    num: '/ 03', title: 'Контракт на приобретение заключается между застройщиком и вами, без посредников.', text: 'Представитель агентства ставит подпись в качестве вашего свидетеля, тем самым гарантируя чистоту сделки.'
  },
]

const Selection = () => {
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    infinity: false,
    initialSlide: 0,
    nextArrow: <NextArrowSlider />,
    prevArrow: <PrevArrowSlider />,
    unslick: true,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          unslick: false,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          unslick: false,
          slidesToShow: 1
        }
      },

    ]
  };

  const selectionItems = items.map((item, i) => {
    return (
      <div key={i} className={cn.item}>
        <div className={cn.item_num}>
          <span>{item.num}</span>
          <div className={cn.item_line}></div>
        </div>
        <div>
          <p className={cn.item_title}>{item.title}</p>
          <p className={cn.item_text}>{item.text}</p>
        </div>
      </div>
    )
  })

  return (
    <div className={`${cn.selection} selection`}>
      <div className={cn.selection_items}>
        <Slider {...settings}>
          {selectionItems}
        </Slider>
      </div>
    </div>
  )
}

export default Selection
