import React from 'react'
import Form from '../Form'
import cn from './Complex.module.sass'
import bgImg from '../../images/complex.png'
import bgImgWebp from '../../images/complex.webp'
import bgImgSm from '../../images/complex-sm.png'
import bgImgSmWebp from '../../images/complex-sm.webp'
import { useState } from 'react'
import { useEffect } from 'react'
import { isWebpSupported } from 'react-image-webp/dist/utils';

const formContent = {
  title: 'Получите консультацию специалиста  прямо сейчас',
  btnText: 'получить консультацию',
  id: 'checkbox_2'
}

const Complex = () => {
  const [bgImage, setBgImage] = useState(isWebpSupported() ? bgImgWebp : bgImg)


  useEffect(() => {
    if (document.documentElement.offsetWidth < 768) {
      setBgImage(isWebpSupported() ? bgImgSmWebp : bgImgSm)
    }
  }, [])

  return (
    <section style={{ backgroundImage: `url(${bgImage})` }} className={cn.root}>
      <div className="container">
        <div className={cn.inner}>
          <div className={cn.content}>
            <h2 className={cn.title}>
              После покупки вы&nbsp;получаете полный комплекс сопровождения
            </h2>
            <ul className={cn.list}>
              <div className={cn.list_item}>
                <li>Перепродажа объекта</li>
                <li>Любые вопросы, которые упростят вашу жизнь</li>
              </div>
              <div className={cn.list_item}>
                <li>Сдача объекта в&nbsp;аренду</li>
                <li>Юридическая помощь и&nbsp;поддержка</li>
              </div>
              <div className={cn.list_item}>
                <li>Устройство детей в&nbsp;британские школы и&nbsp;колледжи</li>
                <li>Гарантированное получение ВНЖ</li>
              </div>
              <div className={cn.list_item}>
                <li>Помощь в&nbsp;покупке машины и&nbsp;другой собственности</li>
                <li>Оформление медицинской страховки</li>
              </div>
            </ul>
          </div>
          <Form {...formContent} />
        </div>
      </div>
    </section>
  )
}

export default Complex
