import React from 'react'
import ReactPixel from 'react-facebook-pixel';
import cn from '../Objects/Objects.module.sass'
import bookImage from '../../images/bookModal.png'
// import emailIcon from '../../images/email.svg'
import InputPhone from '../Form/InputPhone'
import InputCheckbox from '../Form/InputCheckbox'
import { useDispatch, useSelector } from 'react-redux'
import { setObjectModal } from '../../redux/slices/modalSlice'
import { enableBodyScroll } from 'body-scroll-lock';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { selectSendingStatus, sendData } from '../../redux/slices/formSlice'
import Loader from '../Loader'

const ObjectsModal = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const status = useSelector(selectSendingStatus)

  const { register, handleSubmit, control, formState: { errors } } = useForm({
    mode: 'onChange'
  });
  const onSubmit = data => {
    if (data.phone && data.phone.length > 9) {
      dispatch(sendData(data))
      // console.log(data)
      enableBodyScroll(document)
    }
  };

  const handleCloseButton = () => {
    dispatch(setObjectModal(false))
    enableBodyScroll(document)
    ReactPixel.track('Purchase', { Purchase: 'PurchaseEvent' });
  }

  if (status === 'success') {
    navigate('/thank')
  }

  return (
    <div className={`${cn.modal} objects_modal`}>
      <div className={cn.modal_inner}>
        <button type='button' onClick={handleCloseButton} className={cn.modal_close}>
          <span></span>
          <span></span>
        </button>
        <img className={cn.modal_image} src={bookImage} alt="" />
        <div className={cn.modal_content}>
          <p className={cn.modal_content_title}>
            Получите обзор комплекса, прайс-лист и планировки квартир, отправив заявку
          </p>
          <p className={cn.modal_content_text}>
            Укажите свои контактные данные для получения презентации и консультации специалиста
          </p>
          <form onSubmit={handleSubmit(onSubmit)} action="#" className={cn.modal_form}>
            <div className={cn.modal_form_box}>
              <InputPhone control={control} errors={errors} />
              {/* <div className={cn.modal_form_input_box}>
                <input
                  {...register('email', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                  })}
                  style={{ border: errors?.email ? '1px solid #b41c1c' : 'none' }}
                  type="text"
                  className={cn.modal_form_input} placeholder='Введите вашу почту'
                />
                <img src={emailIcon} alt="" />
              </div> */}
              {status === 'loading' ?
                <Loader /> :
                <button type='submit' className={cn.modal_form_btn}>скачать каталог</button>
              }
            </div>
            <div className={cn.modal_form_box}>
              <InputCheckbox errors={errors} register={register} />
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default ObjectsModal
