import React, { useState, useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel';
import cn from './MainScreen.module.sass'
import mainBg from '../../images/main.jpg'
import mainBgWebp from '../../images/main.webp'
import mainBgSm from '../../images/main-sm.jpg'
import mainBgSmWebp from '../../images/main-sm.webp'
import locationgImage from '../../images/location.svg'
import lineSVG from '../../images/line.svg'
import starImage from '../../images/okay.svg'
import Menu from '../Menu'
import { useDispatch } from 'react-redux'
import { setFormContent, setVisibleModal } from '../../redux/slices/modalSlice'
import { disableBodyScroll } from 'body-scroll-lock';
import { isWebpSupported } from 'react-image-webp/dist/utils';

const items = [
  {
    num: '01',
    span: 'Рассрочка от 30% первого взноса',
    text: 'на срок до 80 месяцев'
  },
  {
    num: '02',
    span: 'Сэкономьте до 1 500 000 рублей',
    text: 'с эксклюзивными предложениями застройщиков'
  },
  {
    num: '03',
    span: 'Вы не платите комиссию.',
    text: 'Нам платит застройщик при заключении сделки'
  }
]

const MainScreen = () => {

  const [bgImage, setBgImage] = useState(isWebpSupported() ? mainBgWebp : mainBg)
  const dispatch = useDispatch()

  useEffect(() => {
    if (document.documentElement.offsetWidth < 900) {
      setBgImage(isWebpSupported() ? mainBgSmWebp : mainBgSm)
    }

  }, [])

  const handleButton = () => {
    dispatch(setFormContent({ btnText: 'подобрать проект', title: 'Оставьте заявку и мы поможем вам подобрать проект' }))
    dispatch(setVisibleModal(true))
    disableBodyScroll(document)
    ReactPixel.track('AddToCart', { toCart: 'AddToCartEvent' });
  }

  const itemsElems = items.map((item, i) => {
    return (
      <li key={i} className={cn.item}>
        <div className={cn.item_num}>
          <img src={starImage} alt="" />
          <span>{item.num}</span>
        </div>
        <p className={cn.item_text}>
          <span>{item.span}</span> {item.text}
        </p>
      </li>
    )
  })

  return (
    <div className={cn.root} style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="container">
        <div className={cn.inner}>
          <Menu />
          <div className={cn.content}>
            <div className={cn.body}>
              <h1 className={cn.body_title}>
                Недвижимость <br /> Северного Кипра <br /> <span>от&nbsp;застройщиков</span>
              </h1>
              <div className={cn.body_price} style={{ backgroundImage: `url(${lineSVG})` }}>
                <span className={cn.body_num}>
                  от $90 000
                </span>
                <p className={cn.body_location}>
                  <img src={locationgImage} alt="" />
                  <span>для жизни, отдыха <br /> и инвестиций</span>
                </p>
              </div>
              <button onClick={handleButton} type='button'>
                Подобрать проект
              </button>
            </div>
            <div className={cn.box}>
              <ul className={cn.items}>
                {itemsElems}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainScreen
