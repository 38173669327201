import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactPixel from 'react-facebook-pixel';
import { setVisibleModal } from '../../redux/slices/modalSlice'
import cn from './Form.module.sass'
import InputCheckbox from './InputCheckbox'
import InputPhone from './InputPhone'
import { enableBodyScroll } from 'body-scroll-lock';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { selectSendingStatus, sendData } from '../../redux/slices/formSlice'
import Loader from '../Loader'



const Form = ({ title, btnText, id, isModal }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const status = useSelector(selectSendingStatus)

  const { register, handleSubmit, control, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const onSubmit = (data) => {
    if (data.phone && data.phone.length > 9) {
      dispatch(sendData(data))
      enableBodyScroll(document)
      ReactPixel.track('Purchase', { Purchase: 'PurchaseEvent' });
    }
  };

  const handleCloseButton = () => {
    dispatch(setVisibleModal(false))
    enableBodyScroll(document)
  }

  if (status === 'success') {
    navigate('/thank')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn.root}>
      {isModal && <button onClick={handleCloseButton} type='button' className={cn.close}>
        <span></span>
        <span></span>
      </button>}
      <p className={cn.title}>
        {title}
      </p>
      <InputPhone control={control} errors={errors} />
      {status === 'loading' ?
        <Loader /> :
        <button className={cn.btn}>
          {btnText}
        </button>
      }
      <div className={cn.policy}>
        <InputCheckbox errors={errors} register={register} id={id} />
      </div>
    </form>
  )
}

export default Form
