import React from 'react'
import { useSelector } from 'react-redux'
import { selectModal } from '../../../redux/slices/modalSlice'
import Form from '../../Form'
import cn from './Modal.module.sass'

const Modal = () => {

  const { formContent } = useSelector(selectModal)

  return (
    <div className={cn.root}>
      <Form {...formContent} isModal={true} />
    </div>
  )
}

export default Modal
