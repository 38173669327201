import React, { useEffect } from 'react'
import Menu from '../../components/Menu';
import cn from './Thank.module.sass';
import bgImg from '../../images/thank.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { resetStatus } from '../../redux/slices/formSlice';
import { selectModal } from '../../redux/slices/modalSlice';
import Modal from '../../components/Modals/Modal/Modal';

const Thank = () => {

  const dispatch = useDispatch()
  const { isVisible } = useSelector(selectModal)

  useEffect(() => {
    dispatch(resetStatus())
  }, [])

  return (
    <div className={cn.root} style={{ backgroundImage: `url(${bgImg})` }}>
      <div className="container">
        <div className={cn.inner}>
          <Menu />
          <div className={cn.box}>
            <div className={cn.content}>
              <h3 className={cn.title}>
                Заявка отправлена, скоро с вами свяжемся
              </h3>
              <p className={cn.text}>
                Благодарим за&nbsp;интерес и&nbsp;предлагаем узнать Северный Кипр еще лучше в&nbsp;видео
              </p>
              <ul className={cn.list}>
                <li className={cn.list_item}>
                  цены на недвижимость
                </li>
                <li className={cn.list_item}>
                  получение ВНЖ
                </li>
                <li className={cn.list_item}>
                  образование в британских школах
                </li>
                <li className={cn.list_item}>
                  банковские условия
                </li>
              </ul>
            </div>
            <div className={cn.video}>
              <iframe width="600" height="340" src="https://www.youtube.com/embed/S359mCFQGgU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
      {isVisible && <Modal />}
    </div>
  )
}

export default Thank



