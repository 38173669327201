import React from 'react'
import Selection from './Selection'
import ReactPixel from 'react-facebook-pixel';
import cn from '../People.module.sass'
import nextArrow from '../../../images/massmedia/arrow_next.svg'
import prevArrow from '../../../images/massmedia/arrow_prev.svg'
import { useDispatch, useSelector } from 'react-redux'
import { decrementCount, incrementCount, selectCount } from '../../../redux/slices/tabSlice'
import Mark from './Mark'
import Support from './Support'
import Conf from './Conf'
import Time from './Time'
import { setFormContent, setVisibleModal } from '../../../redux/slices/modalSlice'
import { disableBodyScroll } from 'body-scroll-lock';

const Slides = () => {

  const dispatch = useDispatch()

  const { counter, bgImage } = useSelector(selectCount)

  const handleButton = () => {
    dispatch(setFormContent({ btnText: 'подобрать недвижимость', title: 'Оставьте заявку и мы поможем вам подобрать недвижимость' }))
    dispatch(setVisibleModal(true))
    disableBodyScroll(document)

    ReactPixel.track('AddToCart', { toCart: 'AddToCartEvent' });
  }

  const _slides = [
    <Selection />,
    <Mark />,
    <Support />,
    <Conf />,
    <Time />
  ]

  return (
    <div className={cn.slides} >
      <img src={bgImage[counter]} alt="" className={`${cn.slides_image}`} />
      {_slides[counter]}
      <div className={cn.box_bottom}>
        <button onClick={handleButton} className={cn.btn}>подобрать <br /> недвижимость</button>
        <div className={cn.swipes}>
          <button disabled={counter === 0} onClick={() => dispatch(decrementCount())} className={cn.swipe}>
            <img src={prevArrow} alt="" />
          </button>
          <button disabled={counter === 4} onClick={() => dispatch(incrementCount())} className={cn.swipe}>
            <img src={nextArrow} alt="" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Slides
