import React, { useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel';
import cn from './Reputation.module.sass';
import bgImg from '../../images/reputation.png';
import bgImgWebp from '../../images/reputation.webp';
import bgImgSm from '../../images/reputation-sm.jpg';
import bgImgSmWebp from '../../images/reputation-sm.webp';
import { setFormContent, setVisibleModal } from '../../redux/slices/modalSlice'
import { disableBodyScroll } from 'body-scroll-lock';
import { useDispatch } from 'react-redux'
import { isWebpSupported } from 'react-image-webp/dist/utils';

const Reputation = () => {

  const dispatch = useDispatch()
  const [bgImage, setBgImage] = useState(isWebpSupported() ? bgImgWebp : bgImg)

  useEffect(() => {
    if (document.documentElement.offsetWidth < 768) {
      setBgImage(isWebpSupported() ? bgImgSmWebp : bgImgSm)
    }
  }, [])

  const handleButton = () => {
    dispatch(setFormContent({ btnText: 'получить консультацию', title: 'Получите консультацию' }))
    dispatch(setVisibleModal(true))
    disableBodyScroll(document)

    ReactPixel.track('AddToCart', { toCart: 'AddToCartEvent' });
  }

  return (
    <section style={{ backgroundImage: `url(${bgImage})` }} className={cn.root}>
      <div className="container">
        <div className={cn.inner}>
          <div className={cn.content}>
            <h2 className={cn.title}>
              <span>“</span>Дорожим репутацией и&nbsp;<span>подбираем как&nbsp;для&nbsp;себя</span><span>”</span>
            </h2>
            <p className={cn.text}>
              С&nbsp;2015 года мы&nbsp;консультируем по&nbsp;приобретению курортной недвижимости по&nbsp;всему миру. Подбираем объект, предоставляем юридическую консультацию и&nbsp;полностью ведем сделку.
            </p>
            <p className={cn.subtext}>
              За&nbsp;это время мы&nbsp;накопили огромный опыт&nbsp;&mdash; более 1700 подобранных объектов и&nbsp;счастливых покупателей.
            </p>
            <ul className={cn.list}>
              <li className={cn.list_item}>
                3 принципа нашей работы:
              </li>
              <li className={cn.list_item}>
                1. Только лучшие объекты под ваш запрос
              </li>
              <li className={cn.list_item}>
                2. Максимальная экономия вашего времени
              </li>
              <li className={cn.list_item}>
                3. Полная конфиденциальность
              </li>
            </ul>
            <button onClick={handleButton} className={cn.btn}>
              получить консультацию
            </button>
          </div>
          <div className={cn.person}>
            <p className={cn.person_name}>
              Анатолий Цехин
            </p>
            <p className={cn.person_info}>
              Основатель People
            </p>
            <span>Real estate</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Reputation
