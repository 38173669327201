
import React, { useState, useEffect } from 'react'
import cn from './Objects.module.sass'
import itemImage1 from '../../images/objects/1.jpg'
import itemImage2 from '../../images/objects/2.jpg'
import itemImage3 from '../../images/objects/3.jpg'
import itemImage4 from '../../images/objects/4.jpg'
import itemImage5 from '../../images/objects/5.jpg'
import itemImage6 from '../../images/objects/6.jpg'
import itemImage7 from '../../images/objects/7.jpg'
import itemImage8 from '../../images/objects/8.jpg'
import itemImage9 from '../../images/objects/9.jpg'
import itemImage10 from '../../images/objects/10.jpg'
import itemImage11 from '../../images/objects/11.jpg'
import itemImage12 from '../../images/objects/12.jpg'
import Object from './Object'
import ObjectsSlider from './ObjectsSlider'

const items = [
  {
    id: 0,
    imgUrl: itemImage1,
    name: 'Caesar Resort',
    topSale: 'топ-продаж'
  },
  {
    id: 1,
    imgUrl: itemImage2,
    name: 'QUERENCIA',
    hitSale: 'хит продаж'
  },
  {
    id: 2,
    imgUrl: itemImage3,
    name: 'Hawaii Homes',
    hitSale: 'хит продаж'
  },
  {
    id: 3,
    imgUrl: itemImage4,
    name: 'Bahamas Homes',
  },
  {
    id: 4,
    imgUrl: itemImage5,
    name: 'Bellapais Villa',
  },
  {
    id: 5,
    imgUrl: itemImage6,
    name: 'Caesar Blue',
  },
  {
    id: 6,
    imgUrl: itemImage7,
    name: 'Kofali Homes',
  },
  {
    id: 7,
    imgUrl: itemImage8,
    name: 'Four Seasons Life II',
  },
  {
    id: 8,
    imgUrl: itemImage9,
    name: 'Venice',
  },
  {
    id: 9,
    imgUrl: itemImage10,
    name: 'Riverside',
  },
  {
    id: 10,
    imgUrl: itemImage11,
    name: 'Grand Sapphire',
  },
  {
    id: 11,
    imgUrl: itemImage12,
    name: 'West park',
  },
]

const Objects = () => {

  const [isVisibleSlider, setVisibleSlider] = useState(false)

  useEffect(() => {
    if (document.documentElement.offsetWidth < 900) {
      setVisibleSlider(true)
    }
  }, [])

  const objectsElems = items.map(obj => {
    return <Object key={obj.id} {...obj} />
  })

  return (
    <section className={`${cn.root} objects`}>
      <div className="container">
        <div className={cn.inner}>
          <h3 className={`${cn.title} title`}>
            Подборка лучших объектов
            Северного Кипра
          </h3>
          {isVisibleSlider ?
            <ObjectsSlider items={objectsElems} /> :
            <div className={cn.list}>
              {objectsElems}
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default Objects
