import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setObjectModal, setVisibleModal } from "./modalSlice";
import axios from "axios";

export const getUserCountry = createAsyncThunk(
  "form/getUserCountry",
  async (_, thunkAPI) => {
    const responce = await axios("https://get.geojs.io/v1/ip.json");
    const countryRes = await axios(
      `https://get.geojs.io/v1/ip/country/${responce.data.ip}`
    );
    const userCountry = countryRes.data;
    thunkAPI.dispatch(setUserCountry(userCountry.toLowerCase().trim()));
  }
);

export const sendData = createAsyncThunk(
  "form/sendFormDataStatus",
  async (data, thunkAPI) => {
    const formData = new FormData();

    for (let key in data) {
      formData.append(`${key}`, data[key]);
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    await fetch("https://people-real.com/send.php", requestOptions);
    thunkAPI.dispatch(setVisibleModal(false));
    thunkAPI.dispatch(setObjectModal(false));
  }
);

const initialState = {
  status: "",
  userCountry: "tr",
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    resetStatus(state, action) {
      state.status = "";
    },
    setUserCountry(state, action) {
      state.userCountry = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(sendData.fulfilled, (state, action) => {
      state.status = "success";
    });
    builder.addCase(sendData.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(getUserCountry.rejected, (state, action) => {
      state.userCountry = "ru";
    });
  },
});

export const { resetStatus, setUserCountry } = formSlice.actions;

export const selectSendingStatus = (state) => state.form.status;
export const selectUserCountry = (state) => state.form.userCountry;

export default formSlice.reducer;
