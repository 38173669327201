import { configureStore } from "@reduxjs/toolkit";
import tab from "./slices/tabSlice";
import modal from "./slices/modalSlice";
import form from "./slices/formSlice";
import iframe from "./slices/iframeSlice";

const store = configureStore({
  reducer: {
    tab,
    modal,
    form,
    iframe,
  },
});

export default store;
