import React from 'react'
import { selectFrameModal } from '../../redux/slices/iframeSlice';
import { selectModal } from '../../redux/slices/modalSlice';
import { useSelector } from 'react-redux';
import ModalVideo from './ModalVideo';
import Modal from './Modal/Modal';
import ObjectsModal from './ObjectsModal';

const Modals = () => {

  const { isVisible, isVisibleObjectModal } = useSelector(selectModal)
  const { isVisibleFrame } = useSelector(selectFrameModal)
  return (
    <>
      {isVisibleFrame && <ModalVideo />}
      {isVisible && <Modal />}
      {isVisibleObjectModal && <ObjectsModal />}
    </>
  )
}

export default Modals
