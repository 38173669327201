import React, { useState } from 'react'
import cn from './Form.module.sass'
import PhoneInput from 'react-phone-input-2'
import './input_styles.css'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectUserCountry } from '../../redux/slices/formSlice'


const InputPhone = ({ register, errors, control }) => {

  const userCountry = useSelector(selectUserCountry)

  const [value, setValue] = useState('')

  return (
    <div className={cn.input_box}>
      <Controller
        control={control}
        name='phone'
        render={({ field: { onChange } }) => (
          <PhoneInput
            value={value}
            rules={{ required: true, minLength: 5 }}
            onChange={e => {
              onChange(e);
              setValue(e);
            }}
            specialLabel={''}
            country={userCountry}
            style={{ border: errors?.phone ? '1px solid #b41c1c' : 'none' }}
          />
        )}
      />
    </div>
  )
}

export default InputPhone
