import React from 'react'
import cn from './Channel.module.sass'
import Video from './Video'
import image1 from '../../images/videos/1.jpeg'
import image2 from '../../images/videos/2.jpeg'
import image3 from '../../images/videos/3.jpeg'
import image4 from '../../images/videos/4.jpeg'
import image5 from '../../images/videos/5.jpg'
import image6 from '../../images/videos/6.jpeg'


const videos = [
  {
    id: 0,
    title: 'Как переехать на Кипр на ПМЖ | План действий',
    imageUrl: image1
  },
  {
    id: 1,
    title: '15 причин почему СЕВЕРНЫЙ КИПР | Почему я выбрал его для переезда | Северный Кипр сегодня',
    imageUrl: image2
  },
  {
    id: 2,
    title: 'Как ПРАВИЛЬНО купить недвижимость на Северном Кипре',
    imageUrl: image3
  },
  {
    id: 3,
    title: 'АРЕНДА или ПОКУПКА | Моя вилла на Северном Кипре',
    imageUrl: image4
  },
  {
    id: 4,
    title: 'Турция или Cеверный Кипр | Где лучше жить?',
    imageUrl: image5
  },
  {
    id: 5,
    title: 'Плюсы и Минусы Северного Кипра',
    imageUrl: image6
  },
]

const Channel = () => {

  return (
    <section className={cn.root}>
      <div className="container">
        <div className={cn.inner}>
          <h2 className={cn.title}>
            Создатели ТОП-1 канала о&nbsp;недвжимости Северного Кипра на&nbsp;Youtube
          </h2>
          <div className={cn.videos}>
            {videos.map(item => {
              return (
                <Video key={item.id} {...item} />
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Channel
