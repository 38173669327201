import React from 'react'
import cn from './MassMedia.module.sass'
import Slider from "react-slick";
import itemImage1 from '../../images/massmedia/1.jpg';
import itemImage2 from '../../images/massmedia/2.jpg';
import itemImage3 from '../../images/massmedia/3.jpg';
import itemImage4 from '../../images/massmedia/4.jpg';
import SliderItem from './SliderItem';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';

const items = [
  {
    id: 0,
    imageUrl: itemImage1,
    text: '«Ежегодно Северный Кипр посещают 2 млн туристов»',
  },
  {
    id: 1,
    imageUrl: itemImage2,
    text: '«В турецкой части острова таится множество открытий: от черепах до древних городов»',
  },
  {
    id: 2,
    imageUrl: itemImage3,
    text: '«Север сохранил обширные зеленые равнины, нетронутые пляжи и очаровательные отдаленные деревни»',
  },
  {
    id: 3,
    imageUrl: itemImage4,
    text: '«Обрамленный песчаными пляжами и укрепленный замками этот регион окаймлен линией гор, которая с обеих сторон сменяется зелеными предгорьями»',
  },
]

const MassMedia = () => {



  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    Infinity: false,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };



  const sliderItems = items.map(item => {
    return <SliderItem key={item.id} {...item} />
  })

  return (
    <section className={`${cn.root} massmedia`}>
      <div className="container">
        <div className={cn.inner}>
          <h3 className={`${cn.title} title`}>
            Мировые СМИ рекомендуют Северный Кипр
          </h3>
          <div className={cn.box}>
            <Slider {...settings}>
              {sliderItems}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MassMedia
