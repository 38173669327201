import React from 'react'
import next_arrow from '../../images/massmedia/arrow_next.svg'

const NextArrow = ({ onClick }) => {
  return (
    <img onClick={onClick} className='slick-next slick-arrow' src={next_arrow} alt="" />
  )
}

export default NextArrow
