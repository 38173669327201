import React from 'react'

const ImgWithFallback = ({
  src,
  fallback,
  typeWebp = 'image/webp',
  ...delegated
}) => {
  return (
    <picture>
      <source srcSet={src} type={typeWebp} />
      <img src={fallback} {...delegated} />
    </picture>
  );
};

export default ImgWithFallback
