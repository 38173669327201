import React from 'react'
import cn from '../People.module.sass'

const Mark = () => {
  return (
    <div className={`${cn.mark} ${cn.slide}`}>
      <p>
        Наши эксперты постоянно оценивают инвестиционные риски, всесторонне исследуют деятельность застройщика, проверяют финансовое состояние и&nbsp;положение в&nbsp;отрасли.
      </p>
      <p>
        Вам будут предоставлены объекты только от&nbsp;проверенных застройщиков, которые уже построили не&nbsp;менее 5&nbsp;жилых комплексов в&nbsp;срок и&nbsp;без замечаний.
      </p>
      <p>
        Если вы зададите нам вопрос о застройщике, мы честно расскажем о его плюсах и минусах.
      </p>
    </div>
  )
}

export default Mark
