import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hiddenFrame, selectFrameModal } from '../../../redux/slices/iframeSlice'
import cn from './ModalVideo.module.sass'
import { enableBodyScroll } from 'body-scroll-lock';

const iframes = [
  <iframe
    width="380"
    height="215"
    src="https://www.youtube.com/embed/gWO-z7qNudU"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  ></iframe>,
  <iframe
    width="380"
    height="215"
    src="https://www.youtube.com/embed/S359mCFQGgU"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  ></iframe>,
  <iframe
    width="380"
    height="215"
    src="https://www.youtube.com/embed/EEcoDpnxl9w"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  ></iframe>,
  <iframe
    width="380"
    height="215"
    src="https://www.youtube.com/embed/DJnANEwhNfA"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  ></iframe>,
  <iframe
    width="380"
    height="215"
    src="https://www.youtube.com/embed/7s8J2pMBmK0"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  ></iframe>,
  <iframe
    width="380"
    height="215"
    src="https://www.youtube.com/embed/ly7Wn00tR2M"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  ></iframe>,
]

const ModalVideo = () => {
  const dispatch = useDispatch()
  const { activeFrameId, isVisibleFrame } = useSelector(selectFrameModal)

  const handleCloseBtn = () => {
    dispatch(hiddenFrame())
    enableBodyScroll(document)
  }

  return (
    <div className={cn.root}>
      <button onClick={handleCloseBtn} type='button' className={cn.close}>
        <span></span>
        <span></span>
      </button>
      {isVisibleFrame ? iframes[activeFrameId] : ''}
    </div>
  )
}

export default ModalVideo
