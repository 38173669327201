import React from 'react'
import cn from '../People.module.sass'

const Conf = () => {
  return (
    <div className={`${cn.conf} ${cn.slide}`}>
      <p>
        Мы&nbsp;гарантируем полную конфиденциальность. Вы&nbsp;никогда не&nbsp;услышите от&nbsp;нас фамилии людей, с&nbsp;кем мы&nbsp;уже работали.
      </p>
    </div>
  )
}

export default Conf
