import React from 'react'
import cn from './Causes.module.sass'
import itemImage1 from '../../images/causes/1.png'
import itemImage2 from '../../images/causes/2.png'
import itemImage3 from '../../images/causes/3.png'
import itemImage4 from '../../images/causes/4.png'
import itemImage5 from '../../images/causes/5.png'
import itemImage6 from '../../images/causes/6.png'



const Causes = () => {

  return (
    <section className={cn.root}>
      <div className="container">
        <div className={cn.inner}>
          <h3 className={`${cn.title} title`}>
            6&nbsp;причин инвестировать в&nbsp;недвижимость Северного Кипра
          </h3>
          <div className={cn.items}>
            <div className={cn.item}>
              <img src={itemImage1} alt='' />
              <h4 className={cn.item_title}>
                Климат <br /> Средиземного моря
              </h4>
              <p className={cn.item_text}>
                340 солнечных дней в&nbsp;году.
                Летом +29&nbsp;градусов, а&nbsp;зимой +15.
                Вы&nbsp;сами решаете, когда закрывать купальный сезон и&nbsp;убирать шорты в&nbsp;гардероб.
              </p>
            </div>
            <div className={cn.item}>
              <img src={itemImage2} alt='' />
              <h4 className={cn.item_title}>
                Инвестиционная привлекательность
              </h4>
              <p className={cn.item_text}>
                Новостройки дают в&nbsp;среднем 20-40% доходности в&nbsp;год от&nbsp;перепродажи.
                И&nbsp;гарантированно по&nbsp;договору
                8%&nbsp;годовых от&nbsp;сдачи в&nbsp;аренду.
              </p>
            </div>
            <div className={cn.item}>
              <img src={itemImage3} alt='' />
              <h4 className={cn.item_title}>
                Безопасность и политический нейтралитет
              </h4>
              <p className={cn.item_text}>
                Правительство обеспечивает лояльные условия для длительного пребывания и&nbsp;гарантирует неприкосновенность частной собственности.
              </p>
            </div>
            <div className={cn.item}>
              <img src={itemImage4} alt='' />
              <h4 className={cn.item_title}>
                Безвизовый въезд <br />
                и гарантированное ВНЖ
              </h4>
              <p className={cn.item_text}>
                Туристам Северного Кипра не&nbsp;требуется виза и&nbsp;мед. страховка. Вы&nbsp;можете прилететь уже завтра. Каждому клиенту после сделки гарантированно обеспечиваем ВНЖ.
              </p>
            </div>
            <div className={cn.item}>
              <img src={itemImage5} alt='' />
              <h4 className={cn.item_title}>
                Безопасность <br /> денег и имущества
              </h4>
              <p className={cn.item_text}>
                Северный Кипр не&nbsp;раскрывает информацию о&nbsp;вашей недвижимости и&nbsp;финансовых операциях другим странам.
              </p>
            </div>
            <div className={cn.item}>
              <img src={itemImage6} alt='' />
              <h4 className={cn.item_title}>
                Британские школы <br />
                и колледжи для детей
              </h4>
              <p className={cn.item_text}>
                Ваш конечный покупатель будет приезжать для постоянного проживания с&nbsp;семьей на&nbsp;Северном Кипре.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Causes
