import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isVisibleFrame: false,
  activeFrameId: null,
};

export const iframeSlice = createSlice({
  name: "iframe",
  initialState,
  reducers: {
    setVisibleFrame(state, action) {
      state.isVisibleFrame = true;
      state.activeFrameId = action.payload;
    },
    hiddenFrame(state, action) {
      state.isVisibleFrame = false;
      state.activeFrameId = null;
    },
  },
});

export const { setVisibleFrame, hiddenFrame } = iframeSlice.actions;

export const selectFrameModal = (state) => state.iframe;

export default iframeSlice.reducer;
