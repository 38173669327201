import React from 'react'
import cn from '../People.module.sass'
const Time = () => {
  return (
    <div className={`${cn.time} ${cn.slide}`} >
      <p>
        Работа строится таким образом, чтобы максимально сэкономить ваше время. Мы&nbsp;берем на&nbsp;себя все сложности&nbsp;&mdash; подбор объекта, его проверку и&nbsp;оформление.
      </p>
      <p>
        Оформление визы, бронирование гостиницы и&nbsp;даже покупку билетов. Подбор программы, объектов недвижимости, проверка и&nbsp;оформление. Вам остается только согласовывать варианты, а&nbsp;потом приехать на&nbsp;просмотр и&nbsp;поставить свою подпись в&nbsp;договоре.
      </p>
    </div >
  )
}

export default Time
